import { createContext } from "react";

export type ToastType = "success" | "error" | "info" | "warning";

type ToastFunc = (message: string, duration?: number) => void;
type ToastFuncWithType = (
  ...args: [
    message: Parameters<ToastFunc>["0"],
    type?: ToastType,
    duration?: Parameters<ToastFunc>["1"]
  ]
) => ReturnType<ToastFunc>;

interface ToastContextValue {
  show: ToastFuncWithType;
  success: ToastFunc;
  error: ToastFunc;
  info: ToastFunc;
  warning: ToastFunc;
}

export const ToastContext = createContext<ToastContextValue | undefined>(
  undefined
);
