import Intercom from "@intercom/messenger-js-sdk";
import { User } from "../types";

export default function initIntercom(user: User) {
  if (user.impersonator) {
    return;
  }

  if (import.meta.env.VITE_APP_INTERCOM_APP_ID) {
    const name =
      user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.email;

    Intercom({
      app_id: import.meta.env.VITE_APP_INTERCOM_APP_ID,
      avatar: user.profilePictureUrl,
      user_id: user.id,
      name,
      email: user.email,
      user_hash: user.intercomHash,
      company: {
        id: user.company?.id,
        name: user.company?.name,
      },
    });
  }
}
