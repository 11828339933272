import React, { useState } from "react";
import * as Toast from "@radix-ui/react-toast";
import style from "./style.module.css";
import { Callout } from "@radix-ui/themes";
import { RadixColors } from "../../types";
import { ToastContext, ToastType } from "./toastContext";

interface ToastMessage {
  message: string;
  type: ToastType;
  duration?: number;
}

const colors: Partial<Record<ToastType, RadixColors>> = {
  error: "red",
  warning: "orange",
  success: "grass",
};

const getColor = (type?: ToastType): RadixColors | undefined => {
  if (!type) {
    return undefined;
  }
  return colors[type] || undefined;
};

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState<ToastMessage | null>(null);

  const show = (message: string, type: ToastType = "info", duration = 3000) => {
    setToast({ message, type, duration });
    setOpen(true);
  };

  const makeShorthand =
    (type: ToastType) => (message: string, duration?: number) =>
      show(message, type, duration);

  const success = makeShorthand("success");
  const error = makeShorthand("error");
  const info = makeShorthand("info");
  const warning = makeShorthand("warning");

  const color = getColor(toast?.type);

  return (
    <ToastContext.Provider value={{ show, success, error, info, warning }}>
      <Toast.Provider swipeDirection="right">
        <Toast.Viewport className={style.ToastViewport} />
        <Toast.Root
          className={style.ToastRoot}
          open={open}
          onOpenChange={setOpen}
          duration={toast?.duration}
          data-accent-color={color}
        >
          <Callout.Root color={color} className={style.ContentRoot}>
            <Toast.Description asChild>
              <Callout.Text>{toast?.message}</Callout.Text>
            </Toast.Description>
          </Callout.Root>
        </Toast.Root>
      </Toast.Provider>
      {children}
    </ToastContext.Provider>
  );
};
