import { Outlet } from "react-router";
import { useCurrentUser } from "./auth/user-context";
import AppLayout from "./components/AppLayout";
import initIntercom from "./services/intercom";
import "./App.css";

function App() {
  const { user, logout } = useCurrentUser();

  if (user) {
    initIntercom(user);
  }

  return (
    <AppLayout user={user} logout={logout}>
      <Outlet />
    </AppLayout>
  );
}

export default App;
