import { createContext } from "react";
import { User } from "../types";

type UserContext = {
  user: User | null;
  login: () => void;
  logout: () => void;
};

export const CurrentUserContext = createContext<UserContext>({
  user: null,
  login: () => {},
  logout: () => {},
});
